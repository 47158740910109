<template>
  <v-app id="app" :class="{
    'app-bg-mobile-tablet': breakpoint === 'xs' || breakpoint === 'sm',
    'app-bg-laptop': breakpoint === 'md' || breakpoint === 'lg',
    'app-bg-xl': breakpoint === 'xl'
  }">
    <MyHeader />
    <v-main id="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { isRtlLang } from './i18n'
import MyHeader from '@/components/Header'

export default {
  name: 'App',
  components: {
    MyHeader,
  },

  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
  },

  watch: {
    '$i18n.locale': function (newLocale) {
      this.$vuetify.rtl = isRtlLang(newLocale)
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
  font-family: "Rubik", sans-serif;
}

#app.app-bg-laptop {
  background-image: url("@/assets/bg_desktop.png");
  background-size: cover;
  background-position: bottom;
}

#app.app-bg-mobile-tablet {
  background-image: url("@/assets/bg.png");
  background-size: cover;
  background-position: bottom;
}

#app.app-bg-xl {
  background-image: url("@/assets/bg_desktop.png");
  background-size: cover;
  background-position: bottom;
}
</style>
