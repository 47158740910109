<template>
  <v-dialog v-model="openModal">
    <div class="more-popup">
      <div class="overlay" />
      <close-button class="close-button" @close="close" />
      <div class="content-container">
        <div class="content">
          <div class="d-flex flex-column align-center justify-center">
            <v-img src="/images/lomed.png" :class="returnClassWithSize('mx-auto lomed')" />
            <h2 class="white--text mt-5 mt-sm-3">
              {{ unit.extraContent.title }}
            </h2>
            <p v-if="unit.extraContent.description" :class="returnClassWithSize('content-details')">
              {{ unit.extraContent.description }}
            </p>
            <v-row>
              <v-col cols="12" class="text-center mt-5">
                <iframe :src="unit.extraContent.url" frameborder="0" allowfullscreen
                  :class="returnClassWithSize('video')" />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import CloseButton from '../../components/CloseButton.vue'
export default {
  name: "MorePopup",
  components: { CloseButton },
  props: {
    value: Boolean,
    unit: {
      type: Object,
      required: true
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },

  },
  methods: {
    close() {
      this.$emit('closePopup')
    },
    returnClassWithSize(className) {
      if (this.breakpoint === "xs" || this.breakpoint === "sm") {
        return className + ' size-small'
      }
      else if (this.breakpoint === 'md' || this.breakpoint === 'lg') {
        return className + ' size-medium'
      }
      else {
        return className + ' size-large'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.more-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    z-index: 2;

    .content {
      z-index: 2;
    }

    .lomed {
      max-width: 150px;

      &.size-large {
        max-width: 300px;
      }

      &.size-medium {
        max-width: 110px;
      }
    }

    .content-details {
      color: white;
      text-align: center;

      &.size-large {
        font-size: 1.3rem;
      }
    }

    .video {
      min-width: 200px;

      &.size-large {
        min-width: 700px;
        min-height: 350px;
      }

      &.size-medium {
        min-width: 600px;
        min-height: 300px;
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #D870AD;
  opacity: .95;
  z-index: 1;
}

.close-button {
  position: absolute;
  z-index: 3;
  top: 10px;
}
</style>
