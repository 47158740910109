import Vue from "vue"
import App from "./App"
import router from "./router"
import store from "./store"
import VueNumericInput from "vue-numeric-input"
import vuetify from "./plugins/vuetify"
import "./styles/global.scss"
import JsonExcel from "vue-json-excel"
import i18n from "./i18n"
import "izitoast/dist/css/iziToast.min.css"
import Notifications from "./plugins/notifications"
import GenericServices from "./plugins/generics"
import globalMixins from "./mixins/global"
import NProgress from "vue-nprogress"

Vue.config.productionTip = false

Vue.component("downloadExcel", JsonExcel)
Vue.use(Notifications)
Vue.use(GenericServices)
Vue.use(VueNumericInput)
Vue.use(NProgress)
Vue.mixin(globalMixins)

const nprogress = new NProgress()
const init = async () => {
  store.$vue = new Vue({
    nprogress,
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app")
}

init()
