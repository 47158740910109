import myAxios from "../../services/myAxios";

const USER = {
  id: null,
  user: null,
  last_name: null,
  tel: null,
  email: null,
  password: null,
  role: null,
};

export default {
  state: {
    users: [],
    nowUser: {},
  },

  getters: {
    user: (state) => {
      return state.user;
    },
    users: (state) => {
      return state.users;
    },
    nowUser: (state) => {
      return state.nowUser;
    }
  },

  mutations: {
    "user/store":(state) => {
    state.nowUser = Object.assign({}, USER);
  },
  "user/update": (state, user) => {
    state.nowUser = user
  }
  },

  actions: {
  "user/store": async(context, payload) => {
    try{
      let {data} = await myAxios.post("/user", payload)
      context.commit("user/store", data)
    }catch(e){
      throw new Error(e);
    }
  },
  "user/update": async(context, payload) => {
    try{
      let {data} = await myAxios.put("/user/updateDetails", payload)
      context.commit("user/update", data)
    }catch(e){
      throw new Error(e);
    }
  }
},
}

