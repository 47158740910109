import axios from "axios"
import swalMixins from "@/mixins/swal"
import genericMixins from "@/mixins/generic"
import pluralize from "pluralize"

export default (endpoint, options = {}) => {
	const error = (e) => {
		// console log for dev
		// console.log("erorr is from " + endpoint);
		swalMixins.methods.timeoutSwal({
			icon: "error",
			title: genericMixins.methods.extractClapErrorHe(e),
		})
	}
	return class {
		constructor() {
			const ModelNames = [pluralize(endpoint)]
			const ModelName = endpoint
			const ModelNameFiltered = `${ModelName}Filtered`

			this.state = {
				[ModelName]: null, //chosen ModelName
				[ModelNames]: [],
				filtered: [],
				...options.state,
			}
			this.getters = {
				[ModelNames]: (state) => state[ModelNames],
				[ModelName]: (state) => state[ModelName],
				[`${ModelName}Show`]: (state) => (id) =>
					state.filtered.find((f) => f._id === id),
				[ModelNameFiltered]: (state) => state.filtered,
				...options.getters,
			}
			this.mutations = {
				//sets all ModelNames
				[`${ModelNames}/set`]: (state, payload) => {
					state[ModelNames] = payload
					state.filtered = [...state[ModelNames]]
				},
				//sets one ModelName
				[`${ModelName}/set`]: (state, payload) => (state[ModelName] = payload),
				//filters the ModelName's array by ModelName's key and ModelName's val
				[`${ModelNames}/filter`]: (state, { key, val }) => {
					state.filtered = !val
						? [...state[ModelNames]]
						: state[ModelNames].filter((f) => f[key] === val)
				},
				//store one ModelName
				[`${ModelName}/store`]: (state, payload) =>
					state[ModelNames].push(payload),
				//destroys one ModelName
				[`${ModelName}/destroy`]: (state, id) =>
					(state[ModelNames] = state[ModelNames].filter((item) => {
						return item._id !== id
					})),
				//updates one ModelName
				[`${ModelName}/update`]: (state, payload) => {
					state[ModelNames] = state[ModelNames].map((item) => {
						if (item._id === payload._id) {
							return payload
						}
						return item
					})
				},
				...options.mutations,
			}
			this.actions = {
				//fetch all ModelNames
				[`${ModelName}/index`]: async (context) => {
					try {
						const { data } = await axios.get(`/${ModelName}`)
						context.commit(`${ModelNames}/set`, data)
					} catch (e) {
						error(e)
					}
				},
				//fetch one ModelName by id
				[`${ModelName}/show`]: async (context, id) => {
					try {
						let { data } = await axios.get(`/${ModelName}/` + id)
						context.commit(`${ModelName}/set`, data)
					} catch (e) {
						error(e)
					}
				},
				//stores one ModelName
				[`${ModelName}/store`]: async (context, payload) => {
					try {
						let { data } = await axios.post(`/${ModelName}`, { ...payload })
						context.commit(`${ModelName}/store`, data)
					} catch (e) {
						error(e)
					}
				},
				//destroys one ModelName
				[`${ModelName}/destroy`]: async (context, id) => {
					try {
						await axios.delete(`/${ModelName}/` + id)
						context.commit(`${ModelName}/destroy`, id)
					} catch (e) {
						error(e)
					}
				},
				//updates one ModelName by its id
				[`${ModelName}/update`]: async (context, payload) => {
					try {
						await axios.put(`/${ModelName}/` + payload._id, payload)
						context.commit(`${ModelName}/update`, payload)
					} catch (e) {
						error(e)
					}
				},
				...options.actions,
			}
		}
	}
}
