<template>
  <v-row>
    <v-col cols="12" class="text-right">
      <v-btn class="mx-2" fab dark small color="primary" @click="close">
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CloseButton",
  methods: {
    close() {
      this.$emit('close')
    }
  }

}
</script>

<style>

</style>
