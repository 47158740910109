<template>
  <div>
    <close-button class="close-button" @close="close" />
    <div class="riddle-loader">
      <p>החידה נטענת, רק עוד רגע</p>
      <v-progress-circular indeterminate color="primary" />
    </div>
    <iframe id="ifrm" ref="ifrm" :src="unit.clapLabGame" @load="focusOnIframe" />
  </div>
</template>

<script>
import CloseButton from "../../components/CloseButton"

export default {
  name: "CurrentRiddle",
  components: { CloseButton },

  computed: {
    course() {
      return this.$route.params.course
    },
    unit() {
      return this.$route.params.unit
    },
    branchID() {
      return this.$route.params.branchID
    },
    playedGame() {
      return this.$store.getters.userGameByUrl
    }
  },
  async mounted() {
    window.addEventListener("message", this.receiveMessage, false)
  },
  methods: {
    focusOnIframe() {
      console.log('loaded')
      let iframe = this.$refs.ifrm.contentWindow
      iframe.focus()
    },
    //this method will send data to the iframe
    //I have the somthing similar inside the iframe file, because im doing two way biding (using iframes)
    passDataToIframe(action, data) {
      let iframe = this.$refs.ifrm.contentWindow
      iframe.postMessage({ action: action, data: data })
    },
    //this is the listener function that will get invoked when reciving message
    //you do what ever you need with it
    //Inside the zim file, I have the same one, because here im sending data as well to the zim file
    receiveMessage(e) {
      switch (e.data.action) {
      case "closeTheGame":
        this.finishGame(e)
        break
      case "finishTheGame":
        this.finishGame(e.data.score)
      }
    },
    async finishGame(score) {
      try {
        await this.$store.dispatch("user/didPlay", { gameUrl: this.unit?.clapLabGame })

        if (!this.playedGame) {
          await this.$store.dispatch("game/over", {
            campaignId: this.$store.state.campaignId,
            branchId: this.branchID,
            score: score / 4,
            content: {
              course: this.course?.title,
              unit: this.unit?.title,
              unitID: this.unit?.id,
              didPlayThisURL: this.unit?.clapLabGame
            }
          })
        } else {
          console.log("can only play without save the score")
        }
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.$router.push({
        name: 'MainArea'
      })
    }
  },
}
</script>

<style scoped lang="scss">
.riddle-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

#ifrm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  border: none;
}

.close-button {
  position: fixed;
  right: 0;
  top: 5px;
  z-index: 4;
}
</style>
