import axios from "axios"

// let baseUrl = "/";

// export const setBaseUrl = (base) => {
//   baseUrl = base;
// };

const myAxios = axios.create({
  baseURL: process.env.VUE_APP_ApiUrl,
})

myAxios.interceptors.request.use((config) => {
  // config.baseURL = baseUrl
  config.headers.Authorization = `Bearer ${window.localStorage.getItem(
    "authenticToken"
  )}`
  return config
})

myAxios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    return Promise.reject(error)
  }
)

export default myAxios
