<template>
  <div class="d-flex justify-center align-center" style="width: 100vw; height: 100vh;">
    <h2 class="primary--text text-center">קמפיין לא נמצא</h2>
  </div>
</template>
<script>
export default {
  name: "NotFound",
}
</script>

