
<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col cols="11" md="7" class="mx-auto mt-5">
            <h2>כניסה למערכת</h2>
            <div>
              <v-row class="mt-6">
                <v-col cols="12">
                  <v-text-field v-model="form.name" label="שם" :rules="nameRules" required />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.tel" label="מספר טלפון" :rules="telRules" required />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" />
                <v-btn color="primary" class="white--text" @click="submit">
                  המשך
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "UserLogin",
  data() {
    return {
      form: {
        name: "",
        tel: "",
      },
      nameRules: [
        v => !!v || 'Name is required',
      ],
      telRules: [
        v => !!v || 'Name is required',
      ],
      userError: false
    }
  },
  computed: {
    userBranchID() {
      return this.$store.getters.userBranch
    }
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch("auth/login", {
          username: this.form?.name,
          phone: this.form?.tel
        })
        await this.$store.dispatch("auth/checkLogin")
        this.userBranchID == null ?
          this.$router.push({ name: 'chooseGroup' }) :
          this.$router.push({ name: 'MainArea' })
      } catch (error) {
        console.log(error)
      }
    },
  }

}
</script>
