<template>
  <my-dialog v-model="openModal" title="טבלת מובילים">
    <div style="width: 100%" slot="content">
      <v-simple-table v-if="winners.length" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>שם</th>
              <th>ניקוד</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="winner in winners" :key="winner.id">
              <td>{{ winner.username }}</td>
              <td>{{ winner.total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </my-dialog>
</template>

<script>
import MyDialog from "@/components/generics/MyDialog.vue"

export default {
  name: "WinnersTableDialog",
  components: { MyDialog },
  props: { value: Boolean, branchID: Number },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    winners() {
      return this.$store.getters.winners
    }

  },
  async mounted() {
    await this.$store.dispatch("group/winners", this.branchID)
  }
}
</script>


