import myAxios from "../../services/myAxios"

export default {
  state:{
    groups: [],
    group: {},
    filtered: [],
    winners: []
  },

  getters:{
    group: (state) => state.group,
    groups: (state) => state.groups,
    filtered: (state) => state.filtered,
    winners: (state) =>  state.winners
  },

  mutations:{
    "group/index":(state, groups) => {
      state.groups = groups
      state.filtered = [...state.groups]
    },
    "group/show":(state, group) => {
      state.group = group
    },
    "group/store":(state, group) => {
      state.groups.push(group)
    },
    "group/update":(state, group) => {
      state.groups = state.groups?.map((video) => {
        if (video.id === group.id) {
          video = group
        }
        return video
      })
    },
    "group/get":(state, group) => {
      state.group = group
    },
    "group/unset":(state) =>{
      state.group = {}
    },
    "group/remove":(state, group) => {
      state.groups = state.groups?.filter(
        (video) => video.id !== group.id
      )
    },
    "group/winners"(state, winners) {
      state.winners = winners?.sort(function (a, b) {  return b-a }).slice(0,5)
    }
  },

  actions:{
    "group/index": async(context) => {
      try{
        let {data} = await myAxios.get(`/campaign/${context.rootState.campaignId}/branches`)
        context.commit("group/index", data)
      }catch(e){
        throw new Error(e)
      }
    },
    "group/show": async(context, id) => {
      try{
        let {data} = await myAxios.get("/branch/"+id)
        context.commit("group/show", data)
      }catch(e){
        throw new Error(e)
      }
    },
    "group/store": async (context,payload) => {
      try{
        let {data} = await myAxios.post("/branch", payload)
        context.commit("group/store", data)
      }catch(e){
        throw new Error(e)
      }
    },
    "group/remove": async (context, id) => {
      try{
        await myAxios.delete("branch/"+id)
        context.commit("group/remove", id)
      }catch(e){
        throw new Error(e)
      }
    },
    "group/update": async (context, payload) => {
      try{
        let {data} = await myAxios.update("/branch/"+payload?.id, payload)
        context.commit("group/update", data)
      }catch(e){
        throw new Error(e)
      }
    },
    "group/connectToGroup": async (context, payload) => {
      try{
        await myAxios.post(`/branch/${context.rootState.campaignId}/${payload?.group_id}/join`)
      }catch(e){
        throw new Error(e)
      }
    },
    "group/winners": async (context,  payload) => {
      try{
        let {data} = await myAxios.get(`branch/${payload}/winners`)
        context.commit("group/winners", data)
      }catch(e){
        throw new Error(e)
      }
    }
  }
}
