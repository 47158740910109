<template>
  <div>
    <v-container>
      <v-img src="/images/melakekim.png" class="choose-image" />
      <v-form ref="form">
        <v-row>
          <v-col cols="11 col-md-7" class="mx-auto mt-10">
            <v-row>
              <v-col cols="12" md="10">
                <h3 class="text-center">
                  בחרו קבוצה
                </h3>
                <v-select v-model="selectedGroup" :items="groups" item-text="name" item-value="id" return-object
                  required />
                <v-text-field v-model="form.email" label="נא הכניסו מייל" :rules="[rules.required, rules.email]"
                  :error-messages="errors.email" />
                <v-img src="/images/button_join.png" class="submit mt-2 mx-auto" @click="submit" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "ChooseGroup",

  data() {
    return {
      form: {
        group_id: null,
        email: null
      },
      rules: {
        required: value => !!value || 'שדה זה הוא שדה חובה',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'כתובת המייל איננה תקינה'
        }
      },
      selectedGroup: {},
      errors: {}
    }
  },
  computed: {
    groups() {
      return this.$store.getters.groups
    },
    hasMail() {
      return this.$store.getters.nowUser?.email
    },
    connectedToGroup() {
      return this.$store.getters.userBranch
    }

  },
  watch: {
    selectedGroup: function (newGroup) {
      this.form.group_id = newGroup?.id
    }
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("group/index")
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {

          await this.$store.dispatch("group/connectToGroup", {
            group_id: this.form?.group_id
          })
          await this.$store.dispatch("user/update", {
            email: this.form?.email,
            isNew: false
          })
          await this.$store.dispatch("auth/checkLogin")
        }
        if (this.hasMail && this.connectedToGroup) {
          this.$router.push({ name: 'MainArea' })
        } else {
          console.log("something got worng")
        }
      }
      catch (error) {
        this.errors = error?.response?.data?.errors || {}
      }
    }
  },
  async mounted() {
    await this.loadData()
  }

}
</script>

<style lang="scss" scoped>
.choose-image {
  max-width: 250px;
  margin: auto;
}

.submit {
  max-width: 230px;
}
</style>
