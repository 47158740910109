import { render, staticRenderFns } from "./ScoreDisplay.vue?vue&type=template&id=cc15f536&scoped=true&"
import script from "./ScoreDisplay.vue?vue&type=script&lang=js&"
export * from "./ScoreDisplay.vue?vue&type=script&lang=js&"
import style0 from "./ScoreDisplay.vue?vue&type=style&index=0&id=cc15f536&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc15f536",
  null
  
)

export default component.exports