const globalMixins = {
  methods: {
    getCategoryLocaleString(str, options = {}) {
      const defaultOptions = { count: 1 };
      const mergedOptions = { ...defaultOptions, ...options };

      let [category, stringType] = str.split(".");
      stringType = stringType || "index";

      const pluralCategory = category.match(/(s|ch)$/)
        ? `${category}es`
        : `${category}s`;
      const categoryString =
        this.$store.getters.campaignMeta?.levels?.[pluralCategory]?.[
          mergedOptions.count === 1 ? "localeSingle" : "locale"
        ] || this.$tc(`${category}.${category}`, mergedOptions.count);
      return this.$t(`category.${stringType}`, {
        category: categoryString,
      });
    },
  },
};

export default globalMixins;
