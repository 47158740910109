<template>
  <div class="score-container" @click="$emit('click')">
    <img src="/images/score.png" class="absolute" alt="score display image">
    <p class="absolute">
      {{ score }} נקודות
    </p>
  </div>
</template>

<script>
export default {
  name: "ScoreDisplay",
  props: {
    score: {
      type: Number,
      require: true,
      default: 0
    }
  }

}
</script>

<style lang="scss" scoped>
.score-container {
  position: relative;

  img {
    max-width: 200px;
  }

  p {
    color: white;
    font-size: 1.4rem;
    text-align: center;
    font-weight: lighter;
  }

  .absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
