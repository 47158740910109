import Vue from "vue"
import VueRouter from "vue-router"
//public views
import NotFound from "../views/NotFound"
//auth details
import UserLogin from "../views/Auth/UserLogin.vue"
import ChooseGroup from "../views/Auth/ChooseGroup.vue"
//user details
import MainArea from "../views/User/MainArea.vue"
import CurrentRiddle from "../views/Unit/Riddle.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "userLogin",
    component: UserLogin,
  },
  {
    path: "/choose-group",
    name: "chooseGroup",
    component: ChooseGroup
  },
  {
    path: "/user",
    name: "MainArea",
    component: MainArea
  },
  {
    path: "/user/riddle/:unit",
    name: "riddlePage",
    component: CurrentRiddle
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
