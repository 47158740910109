import _ from "lodash";
import myAxios from "../../services/myAxios";

export default {
  state: {
    game: {},
    games: [],
    gameOver: {},
    userGameByUrl: {},
    totalScore: {},
    userGames: [],
  },

  getters: {
    game: (state) => state.game,
    games: (state) => state.games,
    gameOver: (state) => state.gameOver,
    userGameByUrl: (state) => state.userGameByUrl,
    userGames: (state) => state.userGames,
    totalScore: (state) => state.totalScore,
  },

  mutations: {
    "game/post": (state, game) => {
      state.game = game;
    },
    "game/index": (state, game) => {
      state.games.push(game);
    },
    "game/over": (state, gameOver) => {
      state.gameOver = gameOver;
    },
    "user/gameByUrl": (state, userGameUrl) => {
      state.userGameByUrl = userGameUrl;
    },
    "user/totalScore": (state, totalScore) => {
      state.totalScore = totalScore;
    },
    "user/games": (state, userGames) => {
      state.userGames = userGames?.map(({ content, ...userGames }) => ({
        ...userGames,
        content: content ? JSON.parse(content) : undefined,
      }));
    },
  },

  actions: {
    "game/store": async (context, payload) => {
      try {
        let { data } = await myAxios.post("/game", payload);
        context.commit("game/store", data);
      } catch (e) {
        throw new Error(e);
      }
    },
    "game/show": async (context, id) => {
      try {
        let { data } = await myAxios.get("/game/" + id);
        context.commit("game/index", data);
      } catch (e) {
        throw new Error(e);
      }
    },
    "game/over": async (context, payload) => {
      try {
        let { data } = await myAxios.post("/game/over", payload);
        context.commit("game/over", data);
      } catch (e) {
        throw new Error(e);
      }
    },
    "user/games": async (context) => {
      try {
        let { data } = await myAxios.get(
          `/campaign/${context.rootState.campaignId}/u-games`
        );
        context.commit("user/games", data);
      } catch (e) {
        throw new Error(e);
      }
    },
    "user/didPlay": async (context, payload) => {
      try {
        let { data } = await myAxios.get(
          `/campaign/${context.rootState.campaignId}/u-games`
        );
        let urlFound;
        if (data) {
          let result = data.find((item) => {
            if (item.content) {
              let url = JSON.parse(item.content).didPlayThisURL;
              return _.isEqual(url, payload.gameUrl);
            }
          });
          if (result) {
            urlFound = JSON.parse(result.content).didPlayThisURL;
          }
        }
        context.commit("user/gameByUrl", urlFound);
      } catch (e) {
        throw new Error(e);
      }
    },
    "user/totalScore": async (context) => {
      try {
        let { data } = await myAxios.get(
          `campaign/${context.rootState.campaignId}/u-total-score`
        );
        context.commit("user/totalScore", data);
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
