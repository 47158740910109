<template>
  <v-dialog v-model="openModal">

    <div class="learn-popup align-center">
      <div class="overlay" />
      <div :class="returnClassWithSize('content-container')">
        <div class="content">
          <close-button class="close-button" @close="close" />
          <v-container fill-height>
            <v-row justify="center">
              <v-col>
                <h1 :class="returnClassWithSize('course-title')">
                  {{ courseTitle }}
                </h1>
                <h1 :class="returnClassWithSize('course-unit')">
                  {{ unit &&  unit.title }}
                </h1>
                <div class="d-flex flex-row justify-space-around mt-5">
                  <div class="d-flex flex-column justify-center align-center">
                    <v-img src="/images/lamadnu.png" :class="returnClassWithSize('learn-button')" class="mouse-pointer"
                      @click="checkIfLearned" :gradient="gradient" />
                    <p :class="returnClassWithSize('learn-button-title')">
                      למדנו את הפרק
                    </p>
                  </div>
                  <div class="d-flex flex-column justify-center align-center">
                    <v-img src="/images/lo_lamadnu.png" :class="returnClassWithSize('learn-button')" @click="close" />
                    <p :class="returnClassWithSize('learn-button-title')">
                      לא למדנו את הפרק
                    </p>
                  </div>
                </div>
                <p :class="returnClassWithSize('learn-title')">
                  {{ didLearn ? "למדנו את הפרק" : "עוד לא למדנו את הפרק" }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import CloseButton from '../../components/CloseButton.vue'

export default {
  name: "LearnPopup",
  components: { CloseButton },
  props: {
    value: Boolean,
    courseTitle: {
      type: String,
      required: true
    },
    unit: {
      type: Object,
      required: true
    },
    branchId: {
      type: Number
    },
    campaignId: {
      type: String
    }

  },

  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
    gradient() {
      return this.didLearn ? "to top right, rgba(50,100,100,.20), rgba(22,30,62,.3)" : ""
    },
    didLearn() {
      return this.$store.getters.userGames?.find(item => item.content.didLearn)
    }
  },
  methods: {
    checkIfLearned() {
      if (this.didLearn) {
        this.$createSwal({
          icon: "success",
          title: "כל הכבוד, למדת כבר את הפרק",
          closeable: true,
          lang: "he"
        })
      } else {
        this.markAsLearned()
      }
    },
    async markAsLearned() {
      await this.$store.dispatch("game/over", {
        campaignId: this.campaignId,
        branchId: this.branchId,
        score: 100,
        content: {
          didLearn: true,
          course: this.courseTitle,
          unit: this.unit?.title,
          unitID: this.unit?.id
        }
      })
      this.$createSwal({
        icon: "success",
        title: "מעולה!",
        closeable: true,
        lang: "he"
      })
      this.close()
    },
    close() {
      this.$emit('getUserScore')
      this.$emit('closePopup')
    },

    returnClassWithSize(className) {
      if (this.breakpoint === "xs" || this.breakpoint === "sm") {
        return className + ' size-small'
      }
      else if (this.breakpoint === "md" || this.breakpoint === "lg") {
        return className + ' size-medium'
      }
      else {
        return className + ' size-large'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.learn-popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .content-container {
    position: relative;
    z-index: 2;
    margin: auto;

    &.size-small {
      max-height: 600px;
      min-height: 400px;
      min-width: 200px;
      max-width: 400px;
    }

    &.size-medium {
      max-height: 700px;
      min-height: 400px;
      min-width: 200px;
      max-width: 400px;
      margin: 10% auto;
    }

    &.size-large {
      max-height: 800px;
      min-height: 800px;
      min-width: 200px;
      max-width: 700px;
    }

    .content {
      background-image: url('@/assets/popup_bg.png');
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 13% 12%;

      .course-title {
        color: #26428D;

        &.size-small {
          font-size: 1.5rem;
        }
      }

      .course-unit {
        color: #2F82C4;

        &.size-small {
          font-size: 1.3rem;
        }
      }

      .learn-button {
        max-width: 6rem;

        &.size-large {
          max-width: 8rem;
        }
      }

      .learn-button-title {
        font-size: 1rem;

        &.size-large {
          font-size: 1.3rem;
        }
      }

      .learn-title {
        font-size: 1.3rem;
        font-weight: lighter;

        &.size-large {
          font-size: 2rem;
        }
      }
    }


  }
}

.close-button {
  position: absolute;
  z-index: 1;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #2F82C4;
  opacity: .4;
  z-index: 1;
}
</style>
