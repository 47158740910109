import Vue from "vue"
import Vuex from "vuex"
import moduleList from "@/store/vuexModules"


Vue.use(Vuex)

const modulesCreator = () => {
  let modules = {}
  moduleList.forEach((m) => {
    modules[m] = require("./modules/" + m).default
  })
  return modules
}

let vuexData = {
  state:{campaignId:"tanach"},
  modules: modulesCreator()
}

const store = new Vuex.Store(vuexData)

export default store
