// import router from "../../router/index";
import myAxios from "../../services/myAxios";

export default {
  state: {
    name: null,
    role: null,
    state: null,
    uid: null,
    campaignId: null,
    campaign: null, // for role 1
    area: null, // for role 2
    branch: null, // for role 3
    class: null, // for role 4
    userDetails: {},
    cid: null,
    isLoggedIn: null,
  },
  getters: {
    userDetails: (state) => state.userDetails,
    name: (state) => state.name,
    isAuth: (state) => state.isLoggedIn,
    uid: (state) => state.uid,
    role: (state) => state.role,
    userPhone: (state) => state.userDetails?.phone,
    userCampaign: (state) => state.campaign,
    userCampaignID:(state) => state.campaignId,
    userArea: (state) => state.area,
    userBranch: (state) => state.branch,
    userClass: (state) => state.class,
    cid: (state) => state.cid,
  },
  mutations: {
    "auth/login": (state, data) => {
      state.isLoggedIn = true;
      state.userDetails = data?.user;
      state.name = data?.user?.username;
      state.uid = data?.user?.id;
      state.role = data?.relation?.role;
      state.campaign = data?.relation?.campaign;
      state.area = data?.relation?.area_id;
      state.branch = data?.relation?.branch;
      state.class = data?.relation?.class;
      state.cid = data?.cid;
    },
    "auth/checkLogin":(state, data) => {
      state.isLoggedIn = true;
      state.userDetails = data?.user;
      state.name = data?.user?.username;
      state.uid = data?.user?.id;
      state.role = data?.relation?.role;
      state.campaignId = data?.relation?.campaign_id;
      state.campaign = data?.relation?.campaign;
      state.area = data?.relation?.area_id;
      state.branch = data?.relation?.branch_id;
      state.class = data?.relation?.class;
      state.cid = data?.cid;
    },
    clearData: (state) => {
      state.isLoggedIn = false;
      state.name = null;
      state.role = null;
      state.uid = null;
    },
  },
  actions: {
    "auth/login": async (context, payload) => {
      try {
        let { data } = await myAxios.post(`/auth/login-phone/${context.rootState.campaignId}`, payload);
        window.localStorage.setItem("authenticToken", data.token);
        context.commit("auth/login", { ...data, cid: context.rootState.campaignId });
      } catch (e) {
        throw new Error(e);
      }
    },
    "auth/checkLogin": async (context) => {
      try{
        let {data} = await myAxios.get(`/auth/checkLogin/${context.rootState.campaignId}?relation=1&user=1`)
        context.commit("auth/checkLogin", data)
      }catch(e){
        throw new Error(e);
      }
    }
  },
};
