const he = {
  appTitle: "Claplab-lms",
  generics: {
    meta: "מידע נוסף",
    content: "תוכן",
    level: "רמה",
    totalScore: "סך כל הנקודות",
    score: "ניקוד | נקודות",
    deleteThe: "למחוק את",
    the: "את",
    home: "בית",
    mainMenu: "תפריט ראשי",
    change: "שנה",
    changes: "שינויים",
    actions: "פעולות",
    delete: "למחוק",
    deleteThis: "למחוק את",
    forceChanges: "כפה שנויים",
    remove: "מחיקה",
    name: "שם",
    show: "הצג",
    add: "הוסף",
    save: "שמור",
    edit: "ערוך",
    toEdit: "לערוך",
    search: "חיפוש",
    new: "חדש",
    cancel: "ביטול",
    confirm: "אישור",
    user: "משתמש",
    users: "משתמשים",
    selectColors: "בחר צבע",
    color: "צבע",
    escorters: "מלווים",
    send: "שלח",
    title: "כותרת",
    message: "הודעה",
    deleteAll: "מחק הכל",
    outbox: "דואר יוצא",
    urgent: "דחוף",
    regular: "רגיל",
    complex: "מורכב",
    days: "ימים",
    date: "תאריך",
    time: "זמן",
    all: "הכל",
    status: "סטטוס",
    approve: "מאשר",
    decline: "דחה",
    reject: "דחה",
    location: "מיקום",
    before: "לפני",
    addressee: "נמען",
    join: "הצטרפות",
    leave: "יציאה",
    requestSent: "בקשתך נשלחה",
    global: "כללי",
    applicant: "הפונה",
    type: "סוג",
    escort: "ליווי",
    sentAt: "נשלח בתאריך",
    newEscortRequests: "בקשת ליווי חדש",
    numberOfPeople: "מספר נפשות",
    oneTime: "חד פעמי",
    permanent: "קבוע",
    moreInfo: "מידע נוסף",
  },
  days: [
    { name: "ראשון", key: "א" },
    { name: "שני", key: "ב" },
    { name: "שלישי", key: "ג" },
    { name: "רביעי", key: "ד" },
    { name: "חמישי", key: "ה" },
    { name: "שישי", key: "ו" },
    { name: "שבת", key: "ז" },
  ],
  date: {
    month: "חודש",
    week: "שבוע",
    day: "יום",
    monthly: "חודשי",
    weekly: "שבועי",
    daily: "יומי",
    today: "היום",
    dateStart: "תאריך התחלה",
    dateEnd: "תאריך סיום",
    creationDate: "תאריך יצירה",
    deletionDate: "תאריך מחיקה",
    timeStart: "זמן התחלה",
    timeEnd: "זמן סיום",
    minutes: "דקות",
    monthsNames: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
  },
  drawer: {
    home: "דף הבית",
  },
  auth: {
    email: "אימייל",
    password: "סיסמה",
    login: "התחברות",
    logout: "התנתקות",
  },
  category: {
    index: "{category}",
    name: "שם {category}",
    edit: "עריכת {category}",
    add: "הוספת {category}",
  },
  campaign: {
    name: "שם הקמפיין",
    edit: "עריכת הקמפיין",
    add: "הוספת קמפיין",
    campaign: "קמפיין | קמפיינים",
  },
  area: {
    name: "שם האזור",
    edit: "עריכת אזור",
    add: "הוספת אזור",
    area: "אזור | אזורים",
    errors: {
      delete: "האם למחוק את האיזור ואת כל מה שקשור אליו",
    },
  },
  branch: {
    name: "שם הסניף",
    edit: "עריכת סניף",
    add: "הוספת סניף",
    branch: "סניף | סניפים",
    errors: {
      delete: "האם למחוק את הסניף ואת כל מה שקשור אליו",
    },
  },
  class: {
    name: "שם הכיתה",
    edit: "עריכת כיתה",
    add: "הוספת כיתה",
    class: "כיתה | כיתות",
  },
  game: {
    name: "שם המשחק",
    edit: "עריכת המשחק",
    add: "הוספת משחק",
    game: "משחק | משחקים",
    gameId: "מזהה המשחק",
  },
  user: {
    name: "שם השחקן",
    edit: "עריכת שחקן",
    add: "הוספת שחקן",
    user: "שחקן | שחקנים",
  },
  winner: {
    name: "שם המנצח",
    edit: "עריכת מנצח",
    add: "הוספת מנצח",
    winner: "מנצח | מנצחים",
    timeTypes: "מנצח לפי זמן",
    winTypes: "סוג המנצח",
  },
  admin: {
    adminLoginTitle: "כניסה לעמוד המנהלים",
  },

  cycles: {
    weekly: "שבועי",
    daily: "יומי",
    single: "חד פעמי",
  },
  userManagement: {
    name: "שם",
    email: "אימייל",
    phone: "טלפון",
    password: "סיסמה",
    role: "סוג משתמש",
    changePassword: "שנה סיסמה",
    new: "הוספת משתמש",
    edit: "עריכת משתמש",
    createUserSuccessMessage: "המשתמש נוסף בהצלחה",
    editUserSuccessMessage: "המשתמש עודכן בהצלחה",
    changeUserPasswordWarning: "הסיסמא של {name} עומדת להשתנות. אתה בטוח?",
    allUsers: "כל המשתמשים",
  },
  userDetails: {
    userDetails: "פרטים אישיים",
    name: "שם",
    phone: "טלפון",
    email: "אימייל",
    address: "כתובת",
  },
  errors: {
    validate: {
      complexPaths: "אנא הכנס שם ולפחות 2 מסלולים",
      paths: "אנא הכנס שם ולפחות 2 נקודות",
      required: "שדה חובה",
      minlen6: "מינימום 6 תוים",
      minlen2: "מינימום 2 תוים",
      maxlen40: "מקסימום 40 תוים",
      choose: "נא לבחור",
      minAndMaxPassenger: "בחר מינמום נוסע אחד ומקסימום {max} נוסעים",
    },
    auth: {
      email: "יש להזין אמייל",
      emailInvalid: "אימייל שגוי",
      phoneInvalid: "טלפון שגוי",
      pass: "יש להזין סיסמה",
      minlen6: "מינימום 6 תוים",
    },
    notEmpty: "לא יכול להיות ריק",
    global: "אירעה שגיאה בלתי צפויה",
    globalShort: "שגיאה",
    missingItems: "פרטים חסרים",
    formInvalid: "נא השלם את כל השדות",
  },
  toasts: {
    approveJoin: "התווסף בהצלחה לליווי {escort} {user}",
    declineJoin: "נדחתה בהצלחה {user} בקשתו של ",
    leftEscort: "יצאת מהליווי {escort}",
    error: "אירעה שגיאה",
    success: "הפעולה הושלמה",
    successStore: "היצירה הושלמה",
    successUpdate: "העריכה הושלמה",
    successDestroy: "המחיקה הושלמה",
  },
  headers: {
    user: "שם השחקן",
    email: "אימייל",
    phone: "טלפון",
    level: "רמה",
    class: "שם הכיתה",
    branch: "שם הסניף",
    area: "שם האזור",
    reminderBySms: "תזכורת באמצעות סמס",
    reminderByMail: "תזכורת באמצעות מייל",
    reminderByWhatsapp: "תזכורת באמצעות וואטסאפ",
  },
}
export default he
