<template>
  <my-dialog v-model="openModal" title="טבלת נקודות">
    <div style="width: 100%" slot="content">
      <v-simple-table v-if="mergedUserGames.length" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>יחידה</th>
              <th>ניקוד</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in mergedUserGames" :key="item.id">
              <td>{{item.course}} {{item.unit}}</td>
              <td>{{ item.score }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </my-dialog>
</template>

<script>
import MyDialog from "@/components/generics/MyDialog.vue"

export default {
  name: "ScoreDialog",
  components: { MyDialog},
  props: {value: Boolean},


  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    mergedUserGames() {
      let obj
      let output = this.$store.getters.userGames?.reduce(function (o, cur) {
        let occurs = o.reduce(function (n, item, i) {
          return (item.unitID === cur?.content?.unitID) ? i : n
        }, -1)
        if (occurs >= 0) {
          o[occurs].course = cur?.content?.course
          o[occurs].unit = cur?.content?.unit
          o[occurs].score = o[occurs].score + cur.score
        } else {
          obj = {
            unitID: cur?.content?.unitID,
            course: cur?.content?.course,
            unit: cur?.content?.unit,
            score: cur?.score
          }
          o = o.concat([obj])
        }
        return o
      }, [])
      return output
    }
  }
}
</script>
