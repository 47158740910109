<template>
  <div class="header-container kuku" :class="{
    'app-bg-mobile-tablet': breakpoint === 'xs' || breakpoint === 'sm',
    'app-bg-laptop': breakpoint === 'md' || breakpoint === 'lg',
    'app-bg-xl': breakpoint === 'xl'
  }">
    <div class="top-images mx-3 mt-5 d-flex flex-row justify-space-between">
      <v-img src="/images/logo_tanach.png" class="top-image mx-3" />
      <v-img src="/images/logo_kanfei.png" class="top-image mx-3" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MyHeader",
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name
    }
  }
}
</script>

<style lang="scss" scoped>

.header-container {
  &.app-bg-laptop {
      min-height: 110px;
    }
  .header {
    max-width: 100vh;


  }

  .top-images {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .top-image {
      max-width: 4.5rem;

      @media(min-width: 370px) {
        max-width: 5.5rem;
      }

      @media(min-width: 400px) {
        max-width: 6.0rem;
      }

      @media(min-width: 600px) {
        max-width: 4.2rem;
      }
    }
  }
}

.app-bg-xl {
  background-image: url('@/assets/header_desktop.png');
  background-size: contain;
  background-repeat: repeat-x;
  min-height: 200px;
}

.app-bg-laptop {
  background-image: url('@/assets/header_desktop.png');
  background-size: contain;
  background-repeat: repeat-x;
  min-height: 145px;
}

.app-bg-mobile-tablet {
  background-image: url('@/assets/header.png');
  background-size: contain;
  background-repeat: repeat-x;
  min-height: 150px;
}
</style>
