<template>
  <div id="main-area" :class="changeResolution">
    <div class="action-buttons d-flex flex-row justify-space-between">
      <v-img src="/images/button_quit.png" class="action-button" @click="quit" />
      <v-img src="/images/button_scores.png" class="action-button" @click="showWinnersPopup = true" />
    </div>

    <score-display class="mt-4" :score="totalScore" @click="showScorePopup = true" />

    <p v-if="userGroup" class="user-name mt-14 text-center">שלום {{ user }} - {{userGroup.name}}</p>

    <div v-if="course" class="course-meta mt-n3">
      <h1 class="course-title text-center">
        {{ course.title }}
      </h1>
      <h2 class="course-unit text-center">
        {{ unit.title }}
      </h2>
    </div>

    <div class="unit-actions">
      <v-row class="mt-n16">
        <v-col cols="6" class="text-center">
          <v-img src="/images/button_did_learn.png" :class="unitActionButtonClass" @click="openLearn" />
        </v-col>
        <v-col cols="6" class="text-center">
          <v-img src="/images/button_answer.png" :class="unitActionButtonClass" @click="openRiddle" />
        </v-col>
        <v-col cols="12">
          <v-img src="/images/button_more.png" :class="unitActionButtonClass" class="mt-n12"
            @click="openMore" />
        </v-col>
      </v-row>
    </div>
    <learn-popup v-if="showLearnPopup" v-model="showLearnPopup" :course-title="course.title" :unit="unit"
      :branchId="branchID" :campaignId="campaignID" @closePopup="showLearnPopup = false" @getUserScore="getUserScore" />
    <more-popup v-if="showMorePopup" v-model="showMorePopup" :unit="unit" @closePopup="showMorePopup = false" />
    <score-dialog v-if="showScorePopup" v-model="showScorePopup" />
    <winners-table-dialog v-if="showWinnersPopup" v-model="showWinnersPopup" :branchID="branchID" />
  </div>
</template>

<script>
import ScoreDisplay from '../../components/ScoreDisplay.vue'
import LearnPopup from '../Unit/LearnPopup.vue'
import MorePopup from '../Unit/MorePopup.vue'
import SwalMixins from '../../mixins/swal'
import ScoreDialog from '../../components/ScoreDialog'
import WinnersTableDialog from '../../components/WinnersTableDialog'
import courses from '@/JSON/courses.json'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)

export default {
  name: 'MainArea',
  components: {
    WinnersTableDialog,
    ScoreDialog,
    ScoreDisplay,
    LearnPopup,
    MorePopup,
  },
  mixins: [SwalMixins],
  data() {
    return {
      showLearnPopup: false,
      showMorePopup: false,
      showScorePopup: false,
      showWinnersPopup: false,
      theCourses: courses,
      course: {},
      datesOfUnits: {},
      unit: {}
    }
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint?.name
    },
    user() {
      return this.$store.getters.name
    },
    totalScore() {
      return this.$store.getters.totalScore?.total
    },
    unitActionButtonClass() {
      let className = 'unit-action-button '
      switch (this.breakpoint) {
      case 'sm':
        className += 'size-sm'
        break
      case 'md':
        className += 'size-md'
        break
      case 'lg':
        className += 'size-lg'
        break
      case 'xl':
        className += 'size-xl'
        break
      }
      return className
    },
    campaignID() {
      return this.$store.getters.userCampaignID
    },
    branchID() {
      return this.$store.getters.userBranch
    },
    userGames() {
      return this.$store.getters.userGames
    },
    changeResolution() {
      let className = ""
      this.breakpoint == 'md' ? className = 'fitResolution' : ""
      return className
    },
    userGroup() {
      return this.$store.getters.groups?.find(item => item.id === this.branchID)
    }
  },

  methods: {
    showLearnPopupFunc(){
      this.showLearnPopup = true
    },

    async importGroups() {
      await this.$store.dispatch("group/index")
    },
    importJSON() {
      import(`@/JSON/groupData/group_${this.branchID}_dates.json`).then(
        (module) => {
          this.datesOfUnits = module.default
          this.upload()
        }
      )
    },
    async load() {
      await this.$store.dispatch('auth/checkLogin')
    },
    upload() {
      console.log(this.datesOfUnits)
      let date = new Date()
      let todayDate = dayjs(date)
      let unitFound = this.datesOfUnits?.datas?.find((item) => {
        let startDateFormated = dayjs(item.startDate, 'D/M/YY')
        let endDateFormated = dayjs(item.endDate, 'D/M/YY')
        return todayDate.isBetween(
          startDateFormated,
          endDateFormated,
          'day',
          '[]'
        )
      })
      if (!unitFound) {
        this.showLearnPopup = false
        this.showMorePopup = false

        this.$createSwal({
          icon: 'error',
          title: 'אנו מצטערים, אך אין יחידת לימוד זמינה כרגע',
          text: 'נא לנסות שוב במועד מאוחר יותר, או לפנות למנהל המערכת',
          showCancelButton: false,
        })
      } else {
        this.course = this.theCourses?.courses?.[unitFound.courseid]
        this.unit = this.course?.units?.[unitFound.unitid]
      }
    },
    async getUserScore() {
      await this.$store.dispatch('user/games')
      await this.$store.dispatch('user/totalScore')
    },
    quit() {
      try {
        localStorage.removeItem('authenticToken')
        window.location.replace('/')
      } catch (error) {
        console.log(error)
      }
    },
    openLearn() {
      if (!this.course?.id) return
      this.showLearnPopup=true
    },
    openMore() {
      if (!this.course?.id) return
      this.showMorePopup=true
    },
    openRiddle() {
      if (!this.course?.id) return
      this.$router.push({
        name: 'riddlePage',
        params: {
          course: this.course,
          unit: this.unit,
          branchID: this.branchID,
        },
      })
    },
  },
  async mounted() {
    await this.importGroups()
    await this.load()
    this.importJSON()
    this.getUserScore()
  },
}
</script>

<style lang="scss" scoped>
.fitResolution {
  transform: scale(0.85);
}

#main-area {
  overflow: hidden;

  .action-buttons {
    .action-button {
      max-width: 160px;
    }
  }

  .user-name {
    font-size: 1.5rem;
    font-weight: normal;
  }

  .course-meta {
    .course-title {
      color: #26428d;
    }

    .course-unit {
      color: #2f82c4;
    }
  }

  .unit-actions {
    .unit-action-button {
      max-width: 7rem;
      margin: auto;

      &.size-sm {
        max-width: 8.5rem;
      }

      &.size-md {
        max-width: 9.5rem;
      }

      &.size-lg {
        max-width: 12rem;
      }

      &.size-xl {
        max-width: 14rem;
      }
    }
  }
}
</style>
